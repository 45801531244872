header {
  .logo {
    padding: 48px 50px;

    img {
      max-width: 100%;
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
  }
  .app-title {
    border-top: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    padding: 22px 0px;
    text-align: center;
    color: #4BB5E1;
    font-weight: bold;
  }
  .back {
    float: right;
    margin-right: 20px;
    background: transparent;
    border: 0;
  }
}
.ReactModal__Overlay {
  background: rgb(0 0 0 / 60%) !important;
}
.ReactModal__Content {
  overflow: initial!important;
}

.modal-wrapper {
  text-align: center;
  position: relative;
  .close-btn {
    position: absolute;
    right: -55px;
    top: -55px;
    border-radius: 100%;
    border: 0px;
    width: 30px;
    height: 30px;
    background: #fff;
  }
  img {
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
}