.find-document-wrapper {
  background: linear-gradient(180deg, rgba(236, 236, 236, 0) 0%, #ECECEC 100%);
  height: 400px;
  max-width: 1022px;
  margin: 0 auto;
  padding: 40px 70px;
  @media screen and (max-width: 767px) {
    max-width: inherit;
    padding: 20px;
  }
  .find-document-form {
    border: 1px solid #4BB5E1;
    border-radius: 5px;
    height: 100%;
  }
  .title {
    display: inline-block;
    background: #4BB5E1;
    color: #fff;
    padding: 10px 100px;
    border-radius: 4px;
    position: relative;
    top: -24px;
    left: -34px;

    @media screen and (max-width: 767px) {
      padding: 10px 20px;
      left: 0px;
      margin: 0 auto;
      display: inline-block;
    }
  }
  .digits {
    span {
      width: 290px;
      padding-top: 6px;
    }
    .input-group {
      input {
        border-radius: 10px;
        text-align: center;
        margin-right: 17px;
        height: 35px;
        width: 35px;
        border: 1px solid #ECECEC;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      }
    }
  }
}
.loading {
  text-align: center;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}