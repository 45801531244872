.content {
  min-height: calc(100vh - 378px);

  @media screen and (min-width: 767px) {
    border-bottom: 84px solid #ececec;
    padding-bottom: 20px;
  }
}
.btn-blue {
  background-color: #FFFFFF;
  border: 1px solid #ECECEC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px!important;
  color: #4BB5E1;
  font-weight: bold;
  width: 200px;
  padding: 10px;
  text-align: center;

  &:disabled {
    opacity: .5;
  }
}